import { useContext } from "react";
import "./tripEditor.css";
import { Col, Container, ListGroup, Row, Button } from "react-bootstrap";
import { AppContext } from "../contexts/applicationContext";
import { Trip } from "../interfaces/trips";
import { TripForm } from "./tripForm";
import { getModifiedTrip, getTripsAtLocation, InterfaceMode } from "../interfaces/application";
import { Location } from "../interfaces/location";
import { removeTrip } from "../services/databaseStore";

//import ImageUploader from "react-images-upload";
//import { RMIUploader } from "@dieyne/react-images-uploader";

// Photo URL (0 or 1, for now)
// You have unsaved changes (in tripEditor)
// Retain Members search boxes' focus on enter
// Save all changes
// Highlight favorite in yellow
// Make favorite pin appear

export function TripEditor({formTrip, setFormTrip, setCurrentView, setShowTripModal}: {
  formTrip: Trip,
  setFormTrip: (t: Trip) => void,
  setCurrentView: (i: InterfaceMode) => void,
  setShowTripModal: (b: boolean) => void
}): JSX.Element {
  const { state, dispatch } = useContext(AppContext);
  
  const remove = async () => {
    const trip = getModifiedTrip(state);
    if (trip === null) {
      // Can't remove null trip!
    } else if (trip.draft) {
      dispatch({ type: "removeTrip", trip: state.editingTrip as number });
    } else {
      // Have to tell remote
      const error = await removeTrip(state.editingTrip as string);
      if (error === null) {
        dispatch({ type: "removeTrip", trip: state.editingTrip as string });
      }
    }
  };

  function addNewTrip() {
    if (state.editingTrip !== null) {
      dispatch({"type": "modifyTrip", trip: formTrip});
    }
    dispatch({"type": "addDraftTrip"})
  }
  
  const selectableTrips = Object.values(getTripsAtLocation(state, state.editingTripLocation as Location));
  /*[
    // Keep trips that are not modified
    ...state.trips.filter((t: Trip) => t.id != null && 
    !Object.values(state.modifiedTrips).map(m => m?.id).includes(t.id)),
    // Add in any modified trips (skipping undefined ones)
    ...Object.values(state.modifiedTrips).filter(Boolean) as Trip[]
  ].filter((possible: Trip) => {
    return trip.where === possible.where;
  });*/

  return (
    <div>
      <Container>
        <Row>
          <Col sm={4}>
            Trips:
            <ListGroup>
              {!!selectableTrips.length && (
                selectableTrips.map((t: Trip) => (
                  <ListGroup.Item
                    key={t.id}
                    action
                    variant={t.id === null ? "success" : 
                      //t.draft || Object.keys(state.modifiedTrips).includes(t.id+"") ? "info" :
                      t.favorite ? "warning" : ""}
                    active={t.id === state.editingTrip}
                    onClick={() => {
                      if (state.editingTrip !== null) {
                        dispatch({ type: "modifyTrip", trip: formTrip });
                      }
                      dispatch({ type: "switchEditingTrip", trip: t.id });
                    }}
                  >
                    {t.name} {t.when ? "- " + (t.when || "?") : ""}
                  </ListGroup.Item>
                ))
              )}
            </ListGroup>
            {!selectableTrips.length && (
              <div className={"m-2"}>
                No other trips yet for this destination.
              </div>
            )}
            <Button
              className="m-2"
              onClick={addNewTrip}
              variant="success"
            >Add New Trip</Button>
            {state.editingTrip !== null && 
              <Button variant="danger" onClick={remove}
                className="m-2 mt-1">
                Delete this trip
              </Button>}
          </Col>
          <Col sm={8}>
            {state.editingTrip !== null ? 
              <TripForm trip={formTrip} setTrip={setFormTrip} setCurrentView={setCurrentView} setShowTripModal={setShowTripModal}></TripForm> :
              "No trip yet"}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
