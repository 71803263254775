import {Feature} from 'geojson';

export type LatitudeLongitude = [number, number];

export interface Location {
  featureId: string
  name: string
  feature: Feature
  latlng: LatitudeLongitude
}
  
export const HOME: LatitudeLongitude = [39.2890272, -76.704407]
export const CENTER: {
  latlng: LatitudeLongitude,
  zoom: number
} = { latlng: [30, 0], zoom: 2 }
