// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export function jsonpath(data: any, path: string): string {
  const targets = path.split(".");
  let current: string;
  do {
    current = targets.shift() as string;
    data = data[current];
  } while (targets.length > 0);
  return data as string;
}