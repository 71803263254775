import { ChangeEvent } from "react";
import { useContext } from "react";
import { useMemo, useState } from "react";
import { AppContext } from "../contexts/applicationContext";
import { Trip } from "../interfaces/trips";
import { jsonpath } from "../utilities/json";

enum DIRECTION {
  ASC = "ascending",
  DESC = "descending"
}
interface SortConfig {
  key: string;
  direction: DIRECTION;
  search: string;
}


function useSortableData(
  sortedTrips: Trip[],
  config: SortConfig = { key: "when", direction: DIRECTION.ASC, search: "" }
) {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...sortedTrips];
    if (sortConfig !== null) {
      const { key, direction, search } = sortConfig;
      if (search !== "") {
        sortableItems = sortableItems.filter((trip) =>
          (trip.name + trip.when + trip.where?.feature?.properties?.name)
            .toLocaleLowerCase()
            .includes(search)
        );
      }
      sortableItems.sort((a, b) => {
        if (jsonpath(a, key) < jsonpath(b, key)) {
          return direction === DIRECTION.ASC ? -1 : 1;
        }
        if (jsonpath(a, key) > jsonpath(b, key)) {
          return direction === DIRECTION.ASC ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [sortedTrips, sortConfig]);

  const requestSort = (key: string) => {
    let direction = DIRECTION.ASC;
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === DIRECTION.ASC
    ) {
      direction = DIRECTION.DESC;
    }
    setSortConfig({ key, direction, search: sortConfig.search });
  };

  const requestFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setSortConfig({
      ...sortConfig,
      search: event.target.value.toLocaleLowerCase(),
    });
  };

  return { sortedTrips: sortedItems, requestSort, requestFilter, sortConfig };
}

export function ListView({
  setShowTripModal,
}: {
  setShowTripModal: (b: boolean) => void;
}): JSX.Element {
  const { state, dispatch } = useContext(AppContext);

  const { sortedTrips, requestSort, requestFilter, sortConfig } =
    useSortableData(Object.values(state.trips));

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  
  const switchEditingTrip = (trip: Trip) => {
    dispatch({ type: "updateEditingTripLocation", location: trip.where });
    dispatch({ type: "switchEditingTrip", trip: trip.id });
    setShowTripModal(true);
  };

  return (
    <div className="card border-warning mb-3 trip-list-card">
      <p className="card-header bg-warning mb-0" style={{fontSize: 'xx-large'}}>Oh the Places You've Gone!</p>
      <div className="card-body">
        <div className="input-group mb-3">
          <span className="input-group-text" style={{background: '#13a2b8'}}>&#x1F50D;</span>
          <input
            type="text"
            className="form-control"
            placeholder={`Search for`}
            onChange={requestFilter}
          />
        </div>

        <table className="table table-hover trip-list">
          <thead className="">
            <tr>
              <th style={{width: '30%'}}>
                <button
                  type="button"
                  onClick={() => requestSort("name")}
                  className={`btn btn-info ${getClassNamesFor("name")}`}
                >
                  Name
                </button>
              </th>
              <th style={{width: '30%'}}>
                <button
                  type="button"
                  onClick={() => requestSort("where.feature.properties.name")}
                  className={`btn btn-info ${getClassNamesFor(
                    "where.feature.properties.name"
                  )}`}
                >
                  Location
                </button>
              </th>
              <th style={{width: '20%'}}>
                <button
                  type="button"
                  onClick={() => requestSort("when")}
                  className={`btn btn-info ${getClassNamesFor("when")}`}
                >
                  Arrival
                </button>
              </th>
              <th style={{width: '20%'}}>
                <button
                  type="button"
                  onClick={() => requestSort("duration")}
                  className={`btn btn-info ${getClassNamesFor("duration")}`}
                >
                  Days
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedTrips.map((trip, index) => (
              <tr
                className={index % 2 == 0 ? "table-info" : "table-light"}
                key={index}
                onClick={()=> {
                  switchEditingTrip(trip);
                }}
              >
                <td style={{width: '30%'}}>{trip.name}</td>
                <td style={{width: '30%'}}>{trip.where?.feature?.properties?.name}</td>
                <td style={{width: '20%'}}>{trip.when}</td>
                <td style={{width: '20%'}}>{trip.duration}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
