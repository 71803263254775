import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { AppContext } from "../contexts/applicationContext";
import { login } from "../services/databaseStore";


export function LoggedOutModal({loginChecked}: {loginChecked: boolean}): JSX.Element {
  const {state, dispatch} = useContext(AppContext);

  async function doLogin() {
    dispatch({type: "login", user: await login()});
  }

  return (
    <div>
      <Modal show={loginChecked && state.user === null} dialogClassName="modal-90w">
        <Modal.Header>
          <span style={{ fontSize: "x-large" }}>Not Logged In</span>
        </Modal.Header>
        <Modal.Body>
          <div>
            You are not logged in. You must be logged in with your Google account to access this website.
          </div>
          <div>
            <button onClick={doLogin}>Log In</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}