import { useEffect, useContext, useState, useRef } from "react";
import ReactTags, { ReactTagsProps, Tag } from "react-tag-autocomplete";
import "./tripEditor.css";
import { Col, Form, Row, Button } from "react-bootstrap";
import { AppContext } from "../contexts/applicationContext";
import { Trip, VisitType } from "../interfaces/trips";
import moment from "moment";
import { typeToPin } from "../utilities/pins";
import { peopleAsTags } from "../utilities/people";
import starEmpty from "../assets/images/starEmpty.png";
import starFilled from "../assets/images/starFilled.png";
import { getModifiedTrip, InterfaceMode } from "../interfaces/application";

interface ReactTagInput extends ReactTagsProps {
  onKeyDown: (e: KeyboardEvent) => void | null;
}

export function TripForm({
  trip, setTrip, setCurrentView, setShowTripModal
}: {
  trip: Trip,
  setTrip: (t: Trip) => void,
  setCurrentView: (i: InterfaceMode) => void,
  setShowTripModal: (b: boolean) => void
}): JSX.Element {
  const { state, dispatch } = useContext(AppContext);

  /**
   * When you select a different trip, it moves the form to ModifiedTrips. Then loads the other trip.
   * When you add a new trip, it moves the form to ModifiedTrips. Then loads the new trip.
   * When you save changes, it moves the form to ModifiedTrips. Then uploads the modifiedtrips.
   */

  const [depart, setDepart] = useState("");
  const [friendlyWhen, setFriendlyWhen] = useState("");
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const duration = (new Date(depart as string).getTime() -
          new Date(trip.when as string).getTime()) / 86400000;
    setTrip({...trip, duration});
    setFriendlyWhen(
      !trip.when
        ? ""
        : `Arrive ${moment(trip.when).format("MMM Do, YYYY")} for ${
          duration
        } day stay`
    );
  }, [depart])

  useEffect(() => {
    const trip = getModifiedTrip(state);
    if (trip !== null) {
      setTrip(trip);
      setDepart(
        moment(trip.when).add(trip.duration, "days").format("YYYY-MM-DD")
      );
      // TODO: Figure out depart
      /*(new Date(action.value as string).getTime() -
          new Date(modifiedTrip.when as string).getTime()) / 86400000;*/
      setFriendlyWhen(
        !trip.when
          ? ""
          : `Arrive ${moment(trip.when).format("MMM Do, YYYY")} for ${
            trip.duration
          } day stay`
      );
    }
  }, [state.editingTrip])

  useEffect(() => {
    if (state.changingCoordinates) {
      console.log("TRIP IFRE", state.editingTripCoordinates)
      setTrip({...trip, coordinates: state.editingTripCoordinates});
      dispatch({type: "finishChangingCoordinates"});
    }
  }, [state.editingTripCoordinates]);

  

  // TODO: Pursue onblur options: https://stackoverflow.com/questions/41832389/updating-a-react-input-text-field-with-the-value-on-onblur-event
  // TODO: favorite and photos editing
  

  function addPhoto(event: React.SyntheticEvent<EventTarget>): void {
    if (event.target === null) {
      throw "No valid event target was given";
    }
    const target = event.target as HTMLInputElement;
    // for now, there's only the one photo url
    /*dispatch({
      type: "editTrip",
      field: "photos" as keyof Trip,
      value: [target.value],
    });*/
    setTrip({...trip, photos: [target.value]});
  }

  function invokeAddPerson() {
    if (autocompleteRef != null && autocompleteRef.current != null) {
      (autocompleteRef.current as ReactTagInput).onKeyDown(new KeyboardEvent('keydown', {'key': "Enter"}));
    }
  }

  function addPerson(person: Tag) {
    dispatch({type: "addPerson", person});
    setTrip({...trip, members:
      trip.members.includes(person.name) ?
        trip.members :
        [...trip.members, person.name]});
  }

  function removePerson(index: number) {
    setTrip({...trip, members: trip.members.filter((v, i) => i !== index)});
  }

  function clearCoordinates() {
    setTrip({...trip, coordinates: null});
    dispatch({type: "clearCoordinates"});
  }

  function startChangingCoordinates() {
    dispatch({type: "startChangingCoordinates"});
    setShowTripModal(false);
    setCurrentView(InterfaceMode.MAP);
  }

  /*
  useEffect(() => {
    setDepart(
      moment(trip.when).add(trip.duration, "days").format("YYYY-MM-DD")
    );
    setFriendlyWhen(
      !trip.when
        ? ""
        : `Arrive ${moment(trip.when).format("MMM Do, YYYY")} for ${
          trip.duration
        } day stay`
    );
  }, [trip.when, trip.duration]);*/

  return (
    <div>
      <div className="mb-3 hstack gap-3">
        <img className="pin-icon" src={typeToPin[trip.type]} />
        <div>
          <span style={{ fontSize: "xx-large" }}>
            A {trip.draft ? "new" : ""} {trip.type} trip to{" "}
            {state.editingTripLocation?.feature.properties?.name}
          </span><br/>
          {trip.coordinates
            ? <small>Specifically at ({trip.coordinates.join(", ")})</small>
            : <small>Generally in {state.editingTripLocation?.feature.properties?.name}</small>}
          <button className="ms-2 btn btn-outline-primary btn-sm" onClick={clearCoordinates}>Reset</button>
          <button className="ms-2 btn btn-outline-primary btn-sm" onClick={startChangingCoordinates}>Reposition</button>
        </div>
      </div>
      <div style={{float: 'right'}}  onClick={()=>setTrip({...trip, favorite: !trip.favorite})}>
        {trip.favorite ? (
          <img width="50px" src={starFilled} />
        ) : (
          <img width="50px" src={starEmpty} />
        )}
      </div>
      <Form>
        <Form.Group className="mb-3" controlId="tripEditor.name" as={Row}>
          <Form.Label column sm={2}>
            Name:
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              value={trip.name}
              name={"name"}
              onChange={(e)=>setTrip({...trip, name: e.target.value})}
            />
          </Col>
        </Form.Group>

        <Form.Group controlId="tripEditor.when" as={Row}>
          <Form.Label column md={2}>
            When:
          </Form.Label>
          <Col md={5}>
            <Form.Control
              type={"date"}
              value={trip.when}
              name={"when"}
              onChange={(e) => setTrip({...trip, when: e.target.value})}
            />
          </Col>
          <Col md={5}>
            <Form.Control
              type={"date"}
              value={depart}
              name={"duration"}
              onChange={(e) => setDepart(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Row className="mb-3">
          <Col md={2}></Col>
          <Col md={10}>
            <small>{friendlyWhen}</small>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="tripEditor.type" as={Row}>
          <Form.Label column sm={2}>
            Type:
          </Form.Label>
          <Col sm={10}>
            <Form.Select
              aria-label="Trip Type Dropdown"
              value={trip.type}
              name={"type"}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setTrip({...trip, type: e.target.value as VisitType})}
            >
              {Object.values(VisitType).map((vt: string) => (
                <option value={vt} key={vt}>
                  {vt}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group className="mb-3" controlId="tripEditor.itinerary">
          <Form.Label>Itinerary:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name={"itinerary"}
            onChange={(e) => setTrip({...trip, itinerary: e.target.value})}
            value={trip.itinerary}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="tripEditor.description">
          <Form.Label>Description:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name={"description"}
            onChange={(e) => setTrip({...trip, description: e.target.value})}
            value={trip.description}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="tripEditor.members" as={Row}>
          <Form.Label column sm={2}>
            Participants:
          </Form.Label>
          <Col sm={9}>
            <ReactTags
              ref={autocompleteRef} 
              suggestions={state.people} // Options to display in the dropdown
              tags={peopleAsTags(trip.members)} // Preselected value to persist in dropdown
              onAddition={addPerson} // Function will trigger on select event
              onDelete={removePerson} // Function will trigger on remove event
              allowNew={true}
              placeholderText="Who else went?"
              minQueryLength={0}
              //displayValue="name" // Property name to display in the dropdown options
              //placeholder="Who else went?"
              //isObject={false}
            />
          </Col>
          <Col sm={1}>
            <Button 
              onClick={invokeAddPerson}>+</Button>
          </Col>
        </Form.Group>
        <Form.Group className="mb-3" controlId="tripEditor.photo" as={Row}>
          <Form.Label column sm={2}>
            Photo URL:
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              value={(trip.photos && trip.photos.length) ? trip.photos[0] : ""}
              name={"photo"}
              onChange={addPhoto}
            />
          </Col>
        </Form.Group>
        {trip.photos && trip.photos[0] && (
          <img src={trip.photos[0]} width="350px" alt="Rad Picture" />
        )}

        {/* <Form.Group className="mb-3" controlId="">
          <Form.Label>Photos</Form.Label> */}
        {/*<RMIUploader onUpload={function (e: any): void {
            throw new Error("Function not implemented.");
          } } onSelect={function (e: any): void {
            throw new Error("Function not implemented.");
          } } onRemove={function (e: any): void {
            throw new Error("Function not implemented.");
          } } warnMessage={""} dataSources={[]} title={""} subTitle={""} clearBtnText={""} insertBtnText={""}              
        ></RMIUploader>*/}
        {/*<ImageUploader
            onChange={onDrop}
            withPreview={true}
            withIcon={true}
            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
          />*/}
        {/* </Form.Group> */}
      </Form>
    </div>
  );
}