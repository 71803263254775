import ALL_LOCATIONS from '../assets/shapes/combined.geo.json';
import { FeatureCollection, Feature } from 'geojson';
import { Location, LatitudeLongitude } from '../interfaces/location';
import turf from 'turf';


export interface DefiniteFeature extends Feature {
  id: string
}

export const LOCATION_MAP: {[id: string]: Location} = {};


export function getFeatureCenter(feature: Feature): LatitudeLongitude {
  // TODO: Switch to an external file of overrides, I think?
  switch (feature.id) {
  case "0400000US12":
    return [28.542111, -81.37903];
  }
  
  const centroid = turf.centroid(feature);
  const lon = centroid.geometry.coordinates[0];
  const lat = centroid.geometry.coordinates[1];
  return [lat, lon];
}

export function setupLocations(): void {
  (ALL_LOCATIONS as FeatureCollection).features.forEach((feature: Feature) => {
    const definiteFeature: DefiniteFeature = feature as DefiniteFeature;
    LOCATION_MAP[definiteFeature.id] = {
      featureId: definiteFeature.id,
      feature: feature,
      name: definiteFeature.properties?.name || "Unknown Location",
      latlng: getFeatureCenter(feature)
    };
  });
}
setupLocations();

export function geolocate(featureId: string): Location {
  return LOCATION_MAP[featureId];
}

export function jitter(latlng: LatitudeLongitude, jitterAmount = 10): LatitudeLongitude {
  const [lat, lng] = latlng;
  return [lat + (Math.random()-.5)/jitterAmount, lng + (Math.random()-.5)/jitterAmount]
}