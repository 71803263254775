import { Trip, DEFAULT_TRIP, TripID } from "./trips";
import { Feature } from "geojson";
import { LatitudeLongitude, Location } from "./location";
import { geolocate } from "../services/locationService";
import { Tag } from "react-tag-autocomplete";
import firebase from "firebase/app";

export enum InterfaceMode {
  MAP = "MAP",
  LIST = "LIST",
  KIOSK = "KIOSK",
}


export interface Application {
  interfaceMode: InterfaceMode;
  loading: boolean;
  /** Special mode where map is clickable to choose a spot for specific coordinates */
  changingCoordinates: boolean;
  /** All the persisted trips */
  trips: Record<TripID, Trip>;
  /** The ID of the trip currently loaded in the TripModal */
  editingTrip: TripID | null;
  editingTripLocation: Location | null;
  editingTripCoordinates: LatitudeLongitude | null;
  /** Auto Incrementing ID number */
  autoID: number;
  /** A map of the modified trips that could be persisted */
  modifiedTrips: Record<TripID, Trip>,
  /** A list of people's names */
  people: Tag[];
  user: firebase.User | null;
}

export const DEFAULT_APPLICATION: Application = {
  interfaceMode: InterfaceMode.MAP,
  loading: false,
  changingCoordinates: false,
  editingTrip: null,
  editingTripLocation: null,
  editingTripCoordinates: null,
  autoID: 0,
  trips: {},
  modifiedTrips: {},
  people: [],
  user: null
};

export interface TripModification {
  trip: Trip;
  id: string;
  wasDraft: boolean;
}

export type Action =
  | { type: "login", user: firebase.User | null }
  | { type: "logout", user: null }
  | { type: "startLoading" }
  | { type: "stopLoading" }
  | { type: "switchEditingTrip"; trip: TripID | null }
  | { type: "addDraftTrip" }
  | { type: "updateEditingTripLocation"; feature?: Feature, location?: Location | null, coordinates?: LatitudeLongitude }
  | { type: "clearCoordinates" }
  | { type: "startChangingCoordinates" }
  | { type: "finishChangingCoordinates" }
  | { type: "updateChangingCoordinates", coordinates: LatitudeLongitude | null }
  | { type: "modifyTrip", trip: Trip }
  | { type: "clearModifications" }
  //| { type: "editTrip"; field: keyof Trip; value: unknown }
  | { type: "loadTrips"; trips: Trip[] }
  | { type: "saveTrip"; modifiedTrips: TripModification[] }
  | { type: "favoriteTrip"; }
  | { type: "removeTrip"; trip: TripID }
  | { type: "addPerson"; person: Tag }
  //| { type: "removePerson"; index: number };

function addDraftTrip(state: Application) {
  return {
    ...state,
    draftModified: false,
    autoID: state.autoID+1,
    // Immediately switch to this trip
    editingTrip: state.autoID,
    modifiedTrips: {
      ...state.modifiedTrips,
      [state.autoID]: { ...DEFAULT_TRIP, where: state.editingTripLocation, coordinates: state.editingTripCoordinates, id: state.autoID }
    }
  }
}

function filterKey<T>(map: Record<string, T>, key: string|number): Record<string, T> {
  const cloned = {...map};
  delete cloned[key];
  return cloned;
}

export function getModifiedTrip(state: Application): Trip|null {
  if (state.editingTrip === null) {
    return null;
  } else if (state.editingTrip in state.modifiedTrips &&
    state.modifiedTrips[state.editingTrip] != null) {
    return { ...state.modifiedTrips[state.editingTrip] } as Trip;
  } else {
    return { ...state.trips[state.editingTrip] };
  }
}

export function getTripsAtLocation(state: Application, location: Location): Record<string, Trip> {
  return {
    ...Object.fromEntries(Object.entries(state.trips)
      .filter(([, trip]: [_: string,trip: Trip]) => trip.where === location)),
    ...Object.fromEntries(Object.entries(state.modifiedTrips)
      .filter(([, trip]: [_: string,trip: Trip]) => trip.where === location)),
  }
}

function unfavoriteTrips(trips: Record<string, Trip>): Record<string, Trip> {
  return {...Object.fromEntries(Object.entries(trips)
    .filter(([, trip]: [id: string, trip: Trip]) => trip.favorite)
    .map(([id, trip]: [id: string, trip: Trip]) => [id, {
      ...trip,
      favorite: false
    }]))}
}

export function applicationReducer(
  state: Application,
  action: Action
): Application {
  let uniquePeople: Set<string>, modifiedTrips: Record<string, Trip>;
  switch (action.type) {
  case "startLoading":
    return {
      ...state,
      loading: true,
    };
  case "stopLoading":
    return {
      ...state,
      loading: false,
    };
  case "login":
    return {
      ...state,
      user: action.user
    }
  case "logout":
    return {
      ...state,
      user: action.user
    }
  case "addDraftTrip":
    return addDraftTrip(state);
  case "updateEditingTripLocation":
    return {
      ...state,
      editingTripLocation: action.feature ? geolocate(action.feature.id as string) : action.location || null,
      editingTripCoordinates: action.coordinates || null
    };
  case "clearCoordinates":
    return {
      ...state,
      editingTripCoordinates: null
    }
  case "startChangingCoordinates":
    return {
      ...state,
      changingCoordinates: true
    }
  case "updateChangingCoordinates":
    return {
      ...state,
      editingTripCoordinates: action.coordinates,
    }
  case "finishChangingCoordinates":
    return {
      ...state,
      changingCoordinates: false,
      /*modifiedTrips: {
        // Keep all the other location's trips
        ...state.modifiedTrips,
        // And modify the current active trip
        [state.editingTrip as string]: {
          ...state.modifiedTrips[state.editingTrip as string],
          coordinates: action.coordinates
        }
      }*/
    }
  case "switchEditingTrip":
    return {
      ...state,
      editingTrip: action.trip
    };
  case "clearModifications":
    return {
      ...state,
      modifiedTrips: {}
    }
  case "modifyTrip":
    modifiedTrips = {};
    if (action.trip.favorite) {
      modifiedTrips = unfavoriteTrips(getTripsAtLocation(state, state.editingTripLocation as Location));
    }
    return {
      ...state,
      modifiedTrips: {
        // Keep all the other location's trips
        ...state.modifiedTrips,
        // Bring in newly unfavorited trips
        ...modifiedTrips,
        // And store this new trip!
        [action.trip.id]: action.trip
      }
    }
  case "removeTrip":
    return {
      ...state,
      // Filter out this trip
      trips: filterKey(state.trips, action.trip),
      // Filter out this trip
      modifiedTrips: filterKey(state.modifiedTrips, action.trip),
      editingTrip: null
    };
  case "saveTrip":
    return {
      ...state,
      // For each modifiedTrips, remove the original trip and add the new modified one
      trips: {
        ...state.trips,
        ...Object.fromEntries(action.modifiedTrips.map(m => [m.id, {...m.trip, draft: false}]))
      },
      // Update editingTrip to be the persisted version
      editingTrip: null,
      // Clear out the modifiedTrips
      modifiedTrips: {}
    };
  case "loadTrips":
    uniquePeople = new Set<string>();
    action.trips.forEach((trip: Trip) => {
      trip.members.forEach((person: string) => {
        uniquePeople.add(person);
      });
    });
    return {
      ...state,
      trips: Object.fromEntries(action.trips.map((trip: Trip) => [trip.id, trip])),
      loading: false,
      people: [...uniquePeople].map((person: string) => ({ id: person, name: person }))
    };
  case "addPerson":
    uniquePeople = new Set<string>([...state.people.map((person: Tag) => person.name)]);
    return {
      ...state,
      people: uniquePeople.has(action.person.name) ? state.people : [...state.people, action.person]
    };
  default:
    console.error("Unknown action:", action, state);
    return state;
  }
}

//type ActionType = Action["type"]

//type ExcludeTypeField<A> = { [K in Exclude<keyof A, "type">]: A[K] }

/*type ExtractActionParameters<A, T> = A extends { type: T }
 ? ExcludeTypeField<A>
 : never*/

/*export function useBetterReducer(reducer: (a: Application, aa: Action) => Application,
initial: Application): [Application, Reducer<Application, Action>] {
  const [state, dispatch] = useReducer(reducer, initial);
  const typedDispatch = (actionType: ActionType,
    values: ExtractActionParameters<Action, ActionType>) => {
    return dispatch({type: actionType, ...values} as Action);
  }
  return [state, typedDispatch];
}*/
