// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import { Trip, TripDTO, VisitType } from "../interfaces/trips";
import { geolocate } from "./locationService";

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: "AIzaSyCIepnQdFGznJHj5cyoxMgEmDe3ICAekVo",
  authDomain: "mm-travels.firebaseapp.com",
  projectId: "mm-travels",
  storageBucket: "mm-travels.appspot.com",
  messagingSenderId: "164863929333",
  appId: "1:164863929333:web:ecdc9c952df683b8d20ebc",
  measurementId: "G-665KNVQR4J"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const db = firebase.firestore();
export const DB_COLLECTION = "visited";

export async function login(): Promise<firebase.User|null> {
  const authService = firebase.auth();
  //await authService.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  const provider = new firebase.auth.GoogleAuthProvider();
  const result = await authService.signInWithPopup(provider);
  //const credential = result.credential;
  //const token = credential?.accessToken;
  const user = result.user;
  return user;
}

export async function logout(): Promise<null> {
  await firebase.auth().signOut();
  return null;
}

/** Build a trip from one we got on the server */
export function hydrateTrip(t: TripDTO, id: string): Trip {
  return {
    ...t,
    id: id,
    where: geolocate(t.where),
    type: t.type as VisitType,
    duration: t.duration != null ? t.duration : 1,
    photos: [],
    draft: false,
    coordinates: t.coordinates
  }
}

/** Prepare a trip for upload to server */
export function dehydrateTrip(t: Trip): TripDTO {
  if (t.where === null) {
    throw "Invalid trip, cannot dehydrate a null location!";
  }
  return {
    name: t.name,
    itinerary: t.itinerary,
    description: t.description,
    where: t.where.featureId,
    type: t.type,
    when: t.when,
    duration: t.duration,
    members: t.members,
    photos: t.photos,
    favorite: t.favorite,
    coordinates: t.coordinates
  }
}

export async function loadTrips(): Promise<Trip[]> {
  const querySnapshot = await db.collection(DB_COLLECTION).get();
  const visits: Trip[] = [];
  querySnapshot.forEach((doc) => {
    const trip: Trip = hydrateTrip(doc.data() as TripDTO, doc.id);
    visits.push(trip);
  });
  return visits;
}


export async function removeTrip(trip: string): Promise<Error | null> {
  if (trip === null) {
    throw "Cannot remove Trip with ID null";
  }
  try {
    await db.collection(DB_COLLECTION).doc(trip).delete();
    return null;
  } catch (error) {
    return error as Error;
  }
}

export async function upsertTrip(trip: Trip): Promise<string> {
  const newTrip: TripDTO = dehydrateTrip(trip);
  if (trip.draft) {
    const result = await db.collection(DB_COLLECTION).add(newTrip);
    trip.id = result.id;
    return result.id;
  } else {
    // Assert that the trip ID is a string
    await db.collection(DB_COLLECTION).doc(trip.id as string).set(newTrip, {merge: true});
    return trip.id as string;
  }
}
