import {useContext, useEffect} from 'react';
import { AppContext } from '../contexts/applicationContext';
import { login, logout } from '../services/databaseStore';
import knownUserImage from '../assets/images/known_user.png';
import loggedOutImage from '../assets/images/logged_out.png';
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";

export function LoginHandler({setLoginChecked}: {setLoginChecked: (b: boolean)=>void}): JSX.Element {
  const { state, dispatch } = useContext(AppContext);

  
  useEffect(() => {
    async function doLogin() {
      // TODO: handle errors gracefully
      const user = await login();
      dispatch({type: "login", user: user});
    }
    firebase.auth().onAuthStateChanged((user) => {
      dispatch({type: "login", user: user});
      if (user === null) {
        doLogin();
      }
      setLoginChecked(true);
    });  
  }, []);

  const updateUserStatus = async () => {
    if (state.user === null) {
      dispatch({type: "login", user: await login()});
    } else {
      dispatch({type: "logout", user: await logout()});
    }
  }

  return <div className="hot-buttons">
    <img 
      className="hot-buttons-center rounded border border-dark"
      src={state.user === null ? loggedOutImage : state.user.photoURL || knownUserImage}
      alt="Login"
      onClick={updateUserStatus}
    />
  </div>;
}