import { InterfaceMode } from "../interfaces/application";
import mapView from "../assets/images/globe.png";
import listView from "../assets/images/list.svg";

export function ModeSelector({
  setCurrentView,
}: {
  setCurrentView: (mode: InterfaceMode) => void;
}): JSX.Element {
  return (
    <div className="mode-selector-container">
      <div className="map mode-selector">
        <img
          className="mode-selector-map"
          src={mapView}
          alt="MapView"
          onClick={() => setCurrentView(InterfaceMode.MAP)}
        />
      </div>
      <div className="list mode-selector">
        <img
          className="mode-selector-list"
          src={listView}
          alt="ListView"
          onClick={() => setCurrentView(InterfaceMode.LIST)}
        />
      </div>
    </div>
  );
}
