import { useEffect } from "react";
import { Marker, useMap } from "react-leaflet";
import { LocatedTrip, Trip } from "../interfaces/trips";
import { getPin } from "../utilities/pins";


export const TripPin = (
  { trip, switchEditingTrip, zoom, setZoom }:
  { trip: LocatedTrip, switchEditingTrip: (trip: Trip) => void, 
    zoom: number, setZoom: (zoom: number)=> void }
): JSX.Element => {
  const map = useMap();

  useEffect( ()=> {
    map.on('zoomend', function() {
      const currentZoom = map.getZoom(); 
      //console.log('eVERYONE KNOWS YOUR NAME', currentZoom)
      setZoom(currentZoom)
    })
  }, []);


  return <Marker
    position={trip.coordinates || trip.where.latlng}
    eventHandlers={{ click: () => switchEditingTrip(trip) }}
    icon={getPin(trip, zoom)}
  ></Marker>
}