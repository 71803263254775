import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import ALL_LOCATIONS from "../assets/shapes/combined.geo.json";
import { Feature, FeatureCollection } from "geojson";
import { Layer, LeafletEvent, LeafletMouseEvent, StyleFunction } from "leaflet";
import { LocatedTrip, Trip } from "../interfaces/trips";
import { AppContext } from "../contexts/applicationContext";
import { useContext, useEffect, useState } from "react";
import { loadTrips } from "../services/databaseStore";
import { homePinIcon } from "../utilities/pins";
import { HotButtons } from "./hotButtons";
import { HOME } from "../interfaces/location";
import { TripPin } from "./tripPin";
import GeoJsonWithUpdates from "./GeoJsonWithUpdates";
import { useStableCallback } from "../utilities/hooks";
import { geolocate } from "../services/locationService";

const BLOCKED_SETTINGS = {
  weight: 3,
  color: "#666",
  fillColor: "red",
  //dashArray: "",
  fillOpacity: 0.3,
  opacity: 0.5,
};
const HIGHLIGHTED_SETTINGS = {
  weight: 3,
  color: "#666",
  fillColor: "cornflowerblue",
  //dashArray: "",
  fillOpacity: 0.3,
  opacity: 0.5,
};
const REGULAR_SETTINGS = {
  color: "#FFFDC8",
  opacity: 0.0,
  weight: 2,
  fill: true,
  fillOpacity: 0.0,
};

const highlightFeature = (e: LeafletEvent) => {
  e.target?.setStyle(HIGHLIGHTED_SETTINGS);
};


export const EditableMap = ({
  setShowTripModal,
  setLoginChecked
}: {
  setShowTripModal: (b: boolean) => void;
  setLoginChecked: (b: boolean) => void;
}): JSX.Element => {
  const { state, dispatch } = useContext(AppContext);
  const trips = state.trips;
  
  const [zoom, setZoom] = useState(5);

  const resetHighlight = (e: LeafletEvent) => {
    if (state.changingCoordinates && state.editingTripLocation?.featureId !== e.target?.feature?.id) {
      e.target?.setStyle(BLOCKED_SETTINGS);
    } else {
      e.target?.setStyle(REGULAR_SETTINGS);
    }
  };
  const resetHighlightStable = useStableCallback(resetHighlight);

  // Load the trips at the start
  useEffect(() => {
    if (state.user !== null) {
      (async () => {
        const trips = await loadTrips();
        dispatch({ type: "loadTrips", trips });
      })();
    }
  }, [state.user]);

  /*
  useEffect(() => {
    window.addEventListener('keypress', e => {
      console.log(e.key)
    });
  }, []);*/

  const handleFeatureClick = (event: LeafletMouseEvent, feature: Feature) => {
    const {lat, lng} = event.latlng;
    if (state.changingCoordinates) {
      if (!state.editingTripLocation?.featureId || state.editingTripLocation?.featureId === feature.id) {
        dispatch({ type: "updateChangingCoordinates", coordinates: [lat, lng]});
        setShowTripModal(true);
      } else {
        alert(`That exact position is outside of ${state.editingTripLocation?.name}. In fact, it's in ${geolocate(feature.id as string).name}. I can't place this trip there!`);
      }
      // TODO: Only accept if it's the current editing trip location; also make others red
      // TODO: Show a little message in this mode
    } else {
      dispatch({ type: "updateEditingTripLocation", feature, coordinates: [lat, lng] });
      dispatch({ type: "addDraftTrip" });
      setShowTripModal(true);
    }
  }
  const handleFeatureClickStable = useStableCallback(handleFeatureClick);

  const labelFeature = (feature: Feature, layer: Layer) => {
    layer.off("click").off("mouseover").off("mouseout");
    layer
      .on("click", (event: LeafletMouseEvent) => {
        handleFeatureClickStable(event, feature);
      })
      .on("mouseover", (e) => {
        highlightFeature(e);
      })
      .on("mouseout", (e) => {
        resetHighlightStable(e);
      });
  };

  const styleFeature: StyleFunction = (feature: Feature | undefined) => {
    if (state.changingCoordinates && state.editingTripLocation?.featureId !== feature?.id) {
      return BLOCKED_SETTINGS;
    } else {
      return REGULAR_SETTINGS;
    }
  }

  const switchEditingTrip = (trip: Trip) => {
    if (state.changingCoordinates) {
      dispatch({ type: "updateChangingCoordinates", coordinates: trip.coordinates})
      setShowTripModal(true);
    } else {
      dispatch({ type: "updateEditingTripLocation", location: trip.where, coordinates: trip.coordinates || undefined });
      dispatch({ type: "switchEditingTrip", trip: trip.id });
      setShowTripModal(true);
    }
  };


  const locations: Record<string, Trip> = {};
  const stackSizes: Record<string, number> = {};
  Object.values(trips).forEach((trip: Trip) => {
    const id = trip.coordinates ? JSON.stringify(trip.coordinates) : trip.where?.featureId;
    if (id) {
      if (!(id in locations)) {
        locations[id] = trip;
        stackSizes[id] = 1;
      } else if (trip.favorite) {
        locations[id] = trip;
        stackSizes[id] += 1;
      } else {
        stackSizes[id] += 1;
      }
    }
  });
  const tripMarkers: Trip[] = Object.values(locations);

  return (
    <>
      <MapContainer
        center={HOME}
        zoom={5}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
        worldCopyJump={true}
        maxZoom={16}
      >
        <HotButtons setLoginChecked={setLoginChecked}></HotButtons>
        <TileLayer
          // attribution="Tiles &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC"
          /*url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/
          /*url="https://{s}.tiles.wmflabs.org/osm-no-labels/{z}/{x}/{y}.png"*/
          //url="https://www.osmap.us/#{z}/{x}/{y}"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
        //subdomains={['a','b','c']}
        />
        <GeoJsonWithUpdates
          data={ALL_LOCATIONS as FeatureCollection}
          style={styleFeature}
          onEachFeature={labelFeature}
        />
        {tripMarkers.map(
          (trip: Trip) =>
            trip.where != null && (
              <TripPin trip={trip as LocatedTrip}
                key={trip.id}
                zoom={zoom}
                setZoom={setZoom}
                switchEditingTrip={switchEditingTrip}
              ></TripPin>
            )
        )}
        <Marker position={[39.2890272, -76.704407]} icon={homePinIcon(zoom)}>
          <Popup>Home &lt;3</Popup>
        </Marker>
      </MapContainer>
    </>);
};
