import React, {createContext, useReducer, Dispatch } from 'react';
import { Application, Action, applicationReducer, DEFAULT_APPLICATION} from '../interfaces/application';

const AppContext = createContext<{
  state: Application,
  dispatch: Dispatch<Action>
}>({ state: DEFAULT_APPLICATION, dispatch: () => null});

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(applicationReducer, DEFAULT_APPLICATION);

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }