import { useState } from "react";
import "./App.css";
import "leaflet/dist/leaflet.css";

import { TripModal } from "./components/tripModal";
import "./assets/css/bootstrap.css";

import { AppProvider } from "./contexts/applicationContext";

import { EditableMap } from "./components/editableMap";
import { ListView } from "./components/listView";
import { InterfaceMode } from "./interfaces/application";
import { ModeSelector } from "./components/modeSelector";
import { LoggedOutModal } from "./components/LoggedOutModal";

function App(): JSX.Element {
  const [showTripModal, setShowTripModal] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState(InterfaceMode.MAP);
  const [loginChecked, setLoginChecked] = useState(false);

  return (
    <AppProvider>
      <ModeSelector setCurrentView={setCurrentView}></ModeSelector>
      {currentView === InterfaceMode.LIST && <ListView setShowTripModal={setShowTripModal}></ListView>}
      <div
        hidden={currentView !== InterfaceMode.MAP}
        className="App"
        style={{ height: "500px" }}
      >
        <header className="App-header">
          <EditableMap setShowTripModal={setShowTripModal} setLoginChecked={setLoginChecked}></EditableMap>
        </header>
      </div>
      <TripModal
        show={showTripModal}
        setShowTripModal={setShowTripModal}
        setCurrentView={setCurrentView}
      ></TripModal>
      <LoggedOutModal loginChecked={loginChecked}></LoggedOutModal>
    </AppProvider>
  );
}

export default App;
