import { Modal, Button } from "react-bootstrap";
import { TripEditor } from "./tripEditor";
import { upsertTrip } from "../services/databaseStore";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts/applicationContext";
import { DEFAULT_TRIP, Trip } from "../interfaces/trips";
import { InterfaceMode, TripModification } from "../interfaces/application";

export const TripModal = ({
  show,
  setShowTripModal,
  setCurrentView
}: {
  show: boolean;
  setShowTripModal: (b: boolean) => void;
  setCurrentView: (i: InterfaceMode) => void;
}): JSX.Element => {
  const { state, dispatch } = useContext(AppContext);
  const [formTrip, setFormTrip] = useState<Trip>({ ...DEFAULT_TRIP });
  const [saveReady, setSaveReady] = useState(false);

  const handleClose = () => {
    dispatch({ type: "clearModifications" });
    setShowTripModal(false);
  };
  const save = () => {
    if (state.editingTrip !== null) {
      dispatch({ type: "modifyTrip", trip: formTrip });
    }
    setSaveReady(true);
  };
  useEffect(() => {
    if (saveReady) {
      if (Object.values(state.modifiedTrips).length) {
        Promise.all(Object.values(state.modifiedTrips)
          .map(async (t: Trip | undefined): Promise<TripModification | undefined> => {
            if (t !== undefined) {
              const wasDraft = t.id === null;
              const id = await upsertTrip(t);
              return { id, trip: { ...t, id }, wasDraft };
            }
            return undefined;
          })).then((modifiedTrips: (TripModification | undefined)[]) => {
          dispatch({
            type: "saveTrip",
            modifiedTrips: modifiedTrips.filter(Boolean) as TripModification[]
          });
        });
      }
    }
    setSaveReady(false);
    setShowTripModal(false);
  }, [saveReady]);

  return (
    <div>
      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <span style={{ fontSize: "x-large" }}>Tell us about your trip!</span>
        </Modal.Header>
        <Modal.Body>
          <TripEditor formTrip={formTrip} setFormTrip={setFormTrip}
            setCurrentView={setCurrentView}
            setShowTripModal={setShowTripModal}></TripEditor>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={save}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
