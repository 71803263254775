import { Ensure } from '../utilities/types';
import {LatitudeLongitude, Location} from './location';

export enum VisitType {
  Trip='',
  Family='Family', 
  Work='Work',
  CampGrampy='Camp Grampy', 
  Fun='Fun',
  Romantic='Romantic'
}

export type TripID = number | string;

export interface TripDTO {
  name: string
  itinerary: string
  description: string
  where: string
  type: string
  when: string
  duration: number
  members: string[]
  photos: string[]
  favorite: boolean
  coordinates: LatitudeLongitude|null
}

export interface Trip {
  /** Numeric IDs indicate ones that are not yet persisted */
  id: TripID
  where: Location | null
  name: string
  itinerary: string
  description: string
  members: string[]
  when: string
  duration: number
  type: VisitType
  photos: string[]
  favorite: boolean
  /** Whether this trip has been saved remotely */
  draft: boolean
  coordinates: LatitudeLongitude|null
}

export type LocatedTrip = Ensure<Trip, 'where'>

export const DEFAULT_TRIP: Trip = {
  id: -1,
  where: null,
  name: "New Trip",
  itinerary: "Arrived: arrival day \nLeft: departure day",
  description: "We went and had a great time!!",
  members: [],
  when: new Date().toJSON().slice(0,10),
  duration: 1,
  type: VisitType.Trip,
  photos: [''],
  favorite: false,
  draft: true,
  coordinates: null
}