import { useMap } from "react-leaflet";
import mapRectangle from "../assets/images/rectangle.svg";
import { CENTER } from "../interfaces/location";
import { LoginHandler } from "./loginHandler";

export function HotButtons({setLoginChecked}: {setLoginChecked: (b: boolean)=>void}): JSX.Element {

  const map = useMap();
  const centerMap = () => {
    map.panTo(CENTER.latlng);
    map.setZoom(CENTER.zoom);
  };

  return (
    <div className="hot-buttons-container">
      <div className="hot-buttons">
        <img
          className="hot-buttons-center"
          src={mapRectangle}
          alt="CenterMap"
          onClick={() => centerMap()}
        />
      </div>
      <LoginHandler setLoginChecked={setLoginChecked}></LoginHandler>
    </div>
  );
}
