import { Icon } from "leaflet";
import { Trip, VisitType } from "../interfaces/trips";

import funPin from "../assets/images/funPin.png";
import romanticPin from "../assets/images/romanticPin.png";
import currentlyHerePin from "../assets/images/currentlyHerePin.png";
import familyPin from "../assets/images/familyPin.png";
import campGrampyPin from "../assets/images/campGrampyPin.png";
import workPin from "../assets/images/workPin.png";
import homePin from "../assets/images/homePin.png";
import tripPin from "../assets/images/tripPin.png";
import moment from "moment";

export const typeToPin = {
  [VisitType.Trip]: tripPin,
  [VisitType.Family]: familyPin,
  [VisitType.Work]: workPin,
  [VisitType.Fun]: funPin,
  [VisitType.Romantic]: romanticPin,
  [VisitType.CampGrampy]: campGrampyPin,
  current: currentlyHerePin,
};

// https://stackoverflow.com/questions/65345016/react-leaflet-add-label-popup-next-to-marker
// TODO: Add marker number pop-out

const ZOOM_SCALER = 5;

export function getPin(trip: Trip, zoom: number): Icon {
  const now = moment().startOf('day')
  const isCurrentTrip = !trip.when? false :
    moment(trip.when) <= now &&
    moment(trip.when).add(trip.duration, "days") >= now;
  const pin = isCurrentTrip
    ? typeToPin.current
    : typeToPin[trip.type] || typeToPin[VisitType.Trip];
  return new Icon({
    iconUrl: pin,
    iconSize: [40*zoom/ZOOM_SCALER, 58*zoom/ZOOM_SCALER], //[40, 58],
    iconAnchor: [0, 58*zoom/ZOOM_SCALER]
  });
}

export const homePinIcon = (zoom: number): Icon => new Icon({
  iconUrl: homePin,
  iconSize: [40*zoom/ZOOM_SCALER, 58*zoom/ZOOM_SCALER],
  iconAnchor: [0, 58*zoom/ZOOM_SCALER]
});
